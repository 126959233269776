/*
Custom .css file for accessbility of the QMR Feedback Modal.
Handles tooltip styling to override the default
for React Bootstrap.
*/

.custom-tooltip .tooltip-inner {
    /* custom-tooltip is intended to make Minimize btn clear to user. */
    background-color: #436ca1; /* blueOneHover color code borrowed from colors.ts */
    color: #ffffff;
    padding: 5px 10px;
    font-size: 12px;
    font-family: 'Roboto';
}

.custom-tooltip .arrow::before {
    border-bottom-color: #436ca1;
}

.custom-tooltip .arrow {
    &::before {
        border-width: 0 0.2rem 0.4rem 0.2rem;
    }
}
