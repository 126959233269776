.my-node-enter {
    height: 0;
}
.my-node-enter-active {
    height: auto;
    transition:
        opacity 300ms,
        transform 300ms;
}
.my-node-exit {
    height: auto;
}
.my-node-exit-active {
    height: 0;
    transition:
        opacity 300ms,
        transform 300ms;
}
