/* 
Custom .css file to override the 
default React Bootstrap toggle switch sizing 
since they don't have built-in scaling for switches
*/

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #1f518f;
    background-color: #1f518f;
}

.custom-control-label::before {
    /* DON'T DELETE - Keep reference in case we want to scale differently */
    /* height: 2rem !important;
    width: calc(3rem + 0.75rem) !important;
    border-radius: 4rem !important; */
    height: 1.5rem !important;
    width: calc(2rem + 0.75rem) !important;
    border-radius: 3rem !important;
}

.custom-control-label::after {
    /* width: calc(2rem - 4px) !important; */
    /* height: calc(2rem - 4px) !important; */
    /* border-radius: calc(3rem - (2rem / 2)) !important; */
    width: calc(1.5rem - 4px) !important;
    height: calc(1.5rem - 4px) !important;
    border-radius: calc(2rem - (1.5rem / 2)) !important;
}

.custom-control-input:checked ~ .custom-control-label::after {
    /* transform: translateX(calc(2rem - 0.25rem)) !important; */
    transform: translateX(calc(1.5rem - 0.25rem)) !important;
}

/* Override default Bootstrap table for the notification settings only */
#notification-setting-table th,
#notification-setting-table td {
    border-top: none !important;
}

#notification-setting-table thead {
    /* Sticky header and a box shadow to make it stand out instead of all complete white */
    background: white;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.5);
    z-index: 99;
}

@media (max-width: 530px) {
    #notification-setting-table th {
        /* Assure smaller screens don't overlap */
        padding: 0 !important;
    }
}
