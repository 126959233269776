.rbt .rbt-token {
    color: #1f518f !important;
    border-radius: unset !important;
    background: #dee6ef !important;
}

.rbt .rbt-token-remove-button {
    background-color: #dee6ef !important;
    font-size: 20px;
}
